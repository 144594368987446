<template>
  <el-row>
    <el-col :span="7">
      <el-page-header @back="goBack" content=""></el-page-header>
    </el-col>
    <el-col :span="9" class="py-30">
      <div class="fs-20 mb-30">过渡信息认证（最近一次）</div>
      <formList
          ref="ruleForm"
          :formLabel="formLabel"
          :form="form"
          formLabelWidth="110px"
          :rules="formRules"
          @handleInput="handleInput"
          @handleClick="submit('ruleForm')"
      >
      </formList>
    </el-col>
  </el-row>
</template>

<script>
import formList from "@/components/formList";
import publicFun from "@/mixins/public";
import interfaceWechat from "@/mixins/interfaceWechat";

export default {
  name: "transitionCertification",
  mixins: [publicFun, interfaceWechat],
  components: {formList},
  data() {
    let mvlicenseValidator = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!/^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z0-9]{1})+([A-Z0-9]{1})+(([京津沪渝桂蒙宁新藏冀晋辽吉黑苏浙皖赣闽鲁粤鄂湘豫川云贵陕甘青琼])?[A-HJ-Z0-9]{1})+([A-NP-Z0-9]{3})+([A-NP-Z0-9挂学警港澳领试超外]{1})+([A-HJ-NP-Z0-9外])?)|([A-Z0-9]{7})$/.test(value)) {
          callback(new Error('请输入正确的车牌号'));
        } else {
          callback();
        }
      }
    };
    return {
      formLabel: [
        {
          colSpan: 24,
          label: '车牌号',
          type: 'text',
          key: 'mvLicense',
          placeholder: '请输入车牌号'
        },
        {
          colSpan: 24,
          label: '过渡汽渡名称',
          type: 'text',
          key: 'stationId',
          placeholder: '请先输入车牌号',
          disabled: true,
          list: []
        },
        {
          colSpan: 24,
          label: '过渡时间',
          type: 'text',
          key: 'transitionTime',
          placeholder: '请先输入车牌号',
          disabled: true,
          list: []
        },
        {
          colSpan: 24,
          label: '过渡金额',
          type: 'text',
          key: 'totalToll',
          placeholder: '请先输入车牌号',
          disabled: true
        },
        {
          colSpan: 24,
          label: '过渡吨位',
          type: 'text',
          key: 'carWeight',
          placeholder: '请先输入车牌号',
          disabled: true,
          list: []
        },
        {
          colSpan: 24,
          label: '提交审核',
          type: 'button',
          loading: false,
          buttonType: 'primary',
          width: '100%',
          mt: '20px'
        }
      ],
      form: {
        unionid: '',
        mvLicense: '',
        stationId: '',
        transitionTime: '',
        totalToll: '',
        carWeight: ''
      },
      formRules: {
        mvLicense: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
          { validator: mvlicenseValidator, trigger: 'blur' }
        ],
        stationId: [
          { required: true, message: '请选择过渡汽渡名称', trigger: 'blur' }
        ],
        transitionTime: [
          { required: true, message: '请选择过渡时间', trigger: 'blur' }
        ],
        totalToll: [
          { required: true, message: '请输入过渡金额', trigger: 'blur' }
        ],
        carWeight: [
          { required: true, message: '请选择过渡吨位', trigger: 'blur' }
        ]
      },
      checked: false,
      TimeId: -1
    }
  },
  mounted() {
    if (this.$route.params.item) {
      let item = JSON.parse(this.$route.params.item);
      this.form.mvLicense = item.mvLicense;
    }
    this.form.unionid = sessionStorage.userinfo ? JSON.parse(sessionStorage.userinfo).unionid : '';
  },
  methods: {
    handleInput(label, value) {
      if (label === '车牌号') {
        this.checkLicense(value);
        this.formLabel[1].type = 'text';
        this.formLabel[1].disabled = true;
        this.formLabel[2].type = 'text';
        this.formLabel[2].disabled = true;
        this.formLabel[3].placeholder = '请先输入车牌号';
        this.formLabel[3].disabled = true;
        this.formLabel[4].type = 'text';
        this.formLabel[4].disabled = true;
        Object.keys(this.form).forEach(key => {
          if (key !== 'mvLicense' && key !== 'unionid') this.form[key] = '';
        });
      }
    },
    checkLicense(value) {
      if (value.length < 6) return;
      if (!/^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z0-9]{1})+([A-Z0-9]{1})+(([京津沪渝桂蒙宁新藏冀晋辽吉黑苏浙皖赣闽鲁粤鄂湘豫川云贵陕甘青琼])?[A-HJ-Z0-9]{1})+([A-NP-Z0-9]{3})+([A-NP-Z0-9挂学警港澳领试超外]{1})+([A-HJ-NP-Z0-9外])?)|([A-Z0-9]{7})$/.test(value)) return;
      clearTimeout(this.TimeId);
      this.TimeId = setTimeout(() => {
        this.querySearch(value);
      }, 800);
    },
    querySearch(queryString) {
      if (queryString.length < 6) return;
      this.$axios
          .post('/wechatpublicaccount/carownerinfo/queryCheckData', {mvlicense: queryString})
          .then(res => {
            const {
              msg,
              result,
              code
            } = res;
            if (code !== 0) {
              if (msg.indexOf('选错信息') > -1) {
                this.$confirm('该车辆之前选错信息，请使用车辆认证!', '提示', {
                  confirmButtonText: '前往车辆认证',
                  type: 'warning',
                  closeOnClickModal: false
                }).then(() => {
                  this.$router.push({
                    name: 'carCertification',
                    params: {
                      item: JSON.stringify('noparams')
                    }
                  });
                }).catch(() => {});
              } else {
                return this.$message.error(msg);
              }
            }
            if (result.length === 0) return this.$message.info('此车辆最近没有过渡记录，请检查车牌。');
            this.formLabel[1].type = 'radio';
            this.formLabel[1].disabled = false;
            this.formLabel[1].list = [];
            result[0].stationName.forEach(item => {
              this.formLabel[1].list.push({ label: item.stationId, title: item.stationName })
            });
            this.formLabel[2].type = 'radio';
            this.formLabel[2].disabled = false;
            this.formLabel[2].list = [];
            result[0].transitionTime.forEach(item => {
              this.formLabel[2].list.push({ label: item, title: item })
            });
            this.formLabel[3].placeholder = '请输入过渡金额';
            this.formLabel[3].disabled = false;
            this.formLabel[4].type = 'radio';
            this.formLabel[4].disabled = false;
            this.formLabel[4].list = [];
            result[0].carWeight.forEach(item => {
              this.formLabel[4].list.push({ label: item, title: item })
            });
            return callback(result);
          })
          .catch(error => {});
    },
    handleSelect(item) {
      this.form = item;
      this.form.mvLicense = item.mvLicense;
    },
    submit(formName) {
      this.$refs[formName].$children[0].validate((valid) => {
        if (valid) {
          this.infoStatus = '提交';
          let params = JSON.parse(JSON.stringify(this.form));
          params.totalToll = Number(this.form.totalToll) * 100;
          this.addOrEditInfo('/carownerinfo/submitExamine', params, ['formLabel', 5], this.goPage, '', 'carInfo' );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>